// external
import React, { useRef, useState } from "react";
import useAnalyticsEventTracker from "../functions/useGoogleAnalyticsEventTracker.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faMagnifyingGlass,
  faMountainSun,
  faTractor,
} from "@fortawesome/free-solid-svg-icons";

// internal
import Button from "../components/Button.js";

const RenderedItem = (item) => {
  const markup = { __html: item }; // To handle results with markup that represents matches from Elasticsearch

  // We know this data is clean as it comes from data input by paid members of the Alpaca association, not the general public
  return <span dangerouslySetInnerHTML={markup} />;
};

const Results = ({ results }) => {
  const listAnimals = [];
  const listAnimalsRef = useRef(null);
  const listCompanies = [];
  const listCompaniesRef = useRef(null);
  const listLocations = [];
  const listLocationsRef = useRef(null);

  const totalAnimalsFound = results.animals?.length ?? 0;
  const totalCompaniesFound = results.companies?.length ?? 0;
  const totalLocationsFound = results.locations?.length ?? 0;

  const hasAnimalsResults = totalAnimalsFound > 0 ? true : false;
  const hasCompaniesResults = totalCompaniesFound > 0 ? true : false;
  const hasLocationsResults = totalLocationsFound > 0 ? true : false;

  results.animals?.forEach((item) => {
    const link = `/alpaca/${item.alpacaId}`;
    listAnimals.push(
      <li key={item.alpacaId}>
        Short Name: {RenderedItem(item.alpacaShortName)}
        <ul>
          <li>Registered Name: {RenderedItem(item.alpacaRegisteredName)}</li>
          <li>
            <a
              href={link}
              data-testid={`list-results-animals-animal-id-${item.alpacaId}`}
            >
              Alpaca info
            </a>
          </li>
        </ul>
      </li>
    );
  });

  results.companies?.forEach((item) => {
    const link = `/farm/${item.id}`;
    listCompanies.push(
      <>
        <li key={item.id}>{RenderedItem(item.name)}</li>
        <ul>
          <li>
            <a
              href={link}
              data-testid={`list-results-companies-farm-id-${item.id}`}
            >
              Farm info
            </a>
          </li>
        </ul>
      </>
    );
  });

  results.locations?.forEach((item) => {
    const link = `/farm/${item.id}`;
    listLocations.push(
      <>
        <li key={item.id}>
          {RenderedItem(item.name)} -{" "}
          {RenderedItem(item?.location?.google?.administrative_area_level_2)},{" "}
          {RenderedItem(item?.location?.google?.administrative_area_level_1)}
        </li>
        <ul>
          <li>
            <a
              href={link}
              data-testid={`list-results-locations-farm-id-${item.id}`}
            >
              Farm info
            </a>
          </li>
        </ul>
      </>
    );
  });

  return (
    <>
      <p data-testid="search-list-results-heading">
        <FontAwesomeIcon icon={faMountainSun} className="icon" />
        Areas{" "}
        {!hasLocationsResults ? (
          0
        ) : (
          <a href="#locations-list">{totalLocationsFound}</a>
        )}{" "}
        - <FontAwesomeIcon icon={faTractor} className="icon" /> Farms{" "}
        {!hasCompaniesResults ? (
          0
        ) : (
          <a href="#companies-list">{totalCompaniesFound}</a>
        )}{" "}
        - 🦙 Alpacas{" "}
        {!hasAnimalsResults ? (
          0
        ) : (
          <a href="#animals-list">{totalAnimalsFound}</a>
        )}
      </p>

      {hasLocationsResults && (
        <>
          <h4 id="locations-list">
            <FontAwesomeIcon icon={faMountainSun} className="icon" />
            Areas - {totalLocationsFound}
          </h4>
          <ul data-testid="list-results-locations" ref={listLocationsRef}>
            {listLocations}
          </ul>
        </>
      )}

      {hasCompaniesResults && (
        <>
          <h4 id="companies-list">
            <FontAwesomeIcon icon={faTractor} className="icon" />
            Farms - {totalCompaniesFound}
          </h4>
          <ul data-testid="list-results-companies" ref={listCompaniesRef}>
            {listCompanies}
          </ul>
        </>
      )}

      {hasAnimalsResults && (
        <>
          <h4 id="animals-list">🦙 Alpacas {totalAnimalsFound}</h4>
          <ul data-testid="list-results-animals" ref={listAnimalsRef}>
            {listAnimals}
          </ul>
        </>
      )}
    </>
  );
};

const Search = () => {
  const gaEventTracker = useAnalyticsEventTracker("Searches");

  const inputRef = useRef();
  const [isLocating, setIsLocating] = useState(false);
  const [results, setResults] = useState();

  const handle_SubmitSearch = async () => {
    setIsLocating(true);
    console.log("[LOG] Submit alpaca text search form");

    const body = new URLSearchParams({ query: inputRef.current.value });

    const searchAnimals = async (body) => {
      const response = await fetch(`/api/animals/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      });

      return await response.json();
    };

    const searchCompanies = async (body) => {
      const response = await fetch(`/api/companies/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      });

      return await response.json();
    };

    const searchLocations = async (body) => {
      const response = await fetch(`/api/locations/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      });

      return await response.json();
    };

    const [animals, companies, locations] = await Promise.all([
      searchAnimals(body),
      searchCompanies(body),
      searchLocations(body),
    ]);

    setResults({
      animals: animals,
      companies: companies,
      locations: locations,
    });
    setIsLocating(false);
  };

  return (
    <>
      <header>
        <h2>Search</h2>
      </header>
      <main id="search-page">
        <div className="search-form">
          <form
            id="form-search-by-alpaca"
            method="post"
            onSubmit={async (e) => {
              e.preventDefault();
              await handle_SubmitSearch();
            }}
          >
            <div>
              <h3>
                <label for="searchInput">Find alpacas, farms, area</label>
              </h3>
            </div>

            <div id="text-search-by-alpaca">
              <input
                name="search-by-alpaca"
                id="search-by-alpaca"
                type="text"
                defaultValue=""
                ref={inputRef}
                placeholder="Find alpaca, farm, area"
                className="pill half"
              />

              <Button
                type="button"
                id="search-alpacas-farms"
                className="pill half-flipped"
                icon={
                  isLocating ? (
                    <FontAwesomeIcon icon={faCog} spin size="xl" />
                  ) : (
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
                  )
                }
                text={isLocating ? "Searching" : "Search"}
                onClick={async (e) => {
                  e.preventDefault();
                  gaEventTracker("search", "alpaca, farm, area");
                  await handle_SubmitSearch();
                }}
              />
            </div>
          </form>
        </div>
        <div className="search-results">
          {
            <p>
              {!results ? null : (
                <>
                  <Results results={results} />
                </>
              )}
            </p>
          }
        </div>
      </main>
    </>
  );
};
export default Search;
