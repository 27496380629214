// external
import React, { useState, useEffect, useRef } from "react";
import useAnalyticsEventTracker from "../functions/useGoogleAnalyticsEventTracker.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceSmile,
  faCog,
  faMagnifyingGlass,
  faKey,
  faHouseFlag,
} from "@fortawesome/free-solid-svg-icons";

// internal
import Button from "./Button.js";

const SearchBar = ({
  countOfPrivateFarms,
  countOfPublicFarms,
  isLocating,
  searchFacets,
  setOriginLatLng,
  setSearchFacets,
  updateVisibleFarms,
  handleClick_CurrentLocation,
  handleClick_SelectLocation,
}) => {
  const gaEventTracker = useAnalyticsEventTracker("Searches");
  const [ready, setReady] = useState(false);

  let googlePlacesAutocompleteRef = useRef();
  const autocompleteInputRef = useRef();
  const formRef = useRef();

  const iconCurrentLocation =
    isLocating && searchFacets.currentLocationToggle ? (
      <FontAwesomeIcon icon={faCog} spin size="xl" />
    ) : (
      <FontAwesomeIcon icon={faFaceSmile} size="xl" />
    );

  const iconSearchAddress =
    isLocating && searchFacets.selectLocationToggle ? (
      <FontAwesomeIcon icon={faCog} spin size="xl" />
    ) : (
      <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
    );

  const currentSearchText =
    isLocating && searchFacets.currentLocationToggle
      ? "Locating"
      : "Near current location";
  const addressSearchText =
    isLocating && searchFacets.selectLocationToggle
      ? "Locating"
      : "Near this location";

  const privateButtonText = `Private ${countOfPrivateFarms}`;
  const publicButtonText = `Public ${countOfPublicFarms}`;

  useEffect(() => {
    // Initialise google autocomplete service

    if (!autocompleteInputRef.current) return;

    googlePlacesAutocompleteRef.current =
      new window.google.maps.places.Autocomplete(autocompleteInputRef.current, {
        // types: ["address"], // Ref: https://developers.google.com/maps/documentation/places/web-service/supported_types
        componentRestrictions: { country: ["NO"] }, // ["AU", "BE", "FR", "US", "GB"] },
        fields: ["place_id", "geometry", "name"], // Ref: https://developers.google.com/maps/documentation/javascript/place-autocomplete#specify-data-fields
      });
  }, []);

  useEffect(() => {
    // Clear address location field if current location is used
    if (searchFacets.currentLocationToggle) {
      autocompleteInputRef.current.value = null;
    }
  }, [searchFacets]);

  useEffect(() => {
    // Update location from selected

    if (!autocompleteInputRef.current) return;

    function onPlaceChanged() {
      const place = googlePlacesAutocompleteRef.current.getPlace();

      // Update search button state
      setReady(false);
      setSearchFacets({
        ...searchFacets,
        currentLocationToggle: false,
      });

      // Reset map from any previous search
      setOriginLatLng(null);

      if (!place.geometry) {
        // User did not select a prediction. Reset the input field.  As per official google info
        // https://developers.google.com/maps/documentation/javascript/place-autocomplete#change-searchbox-area
        // https://www.youtube.com/watch?v=c3MjU9E9buQ&t=87s

        // Update search button state
        setReady(false);

        // Update search field state
        console.log(
          "[LOG] Reset. User did not select a prediction. No place.name: ",
          place.name
        );
        formRef.current.reset();
      } else {
        // Update map
        const originLatLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setOriginLatLng(originLatLng);

        // Update search button state
        setReady(true);
      }
    }

    googlePlacesAutocompleteRef.current.addListener(
      "place_changed",
      onPlaceChanged
    );
  }, [setOriginLatLng, searchFacets, setSearchFacets]);

  return (
    <>
      <div id="map-search">
        <div id="map-search-modifier">
          <div id="map-search-by-current-location">
            <Button
              type="button"
              onClick={() => {
                gaEventTracker("search", "current location");

                handleClick_CurrentLocation({
                  ...searchFacets,
                  currentLocationToggle: !searchFacets.currentLocationToggle,
                });
                setSearchFacets({
                  ...searchFacets,
                  currentLocationToggle: !searchFacets.currentLocationToggle,
                  selectLocationToggle: false,
                });
              }}
              className={
                !searchFacets.currentLocationToggle
                  ? "pill full facet"
                  : "pill full"
              }
              icon={iconCurrentLocation}
              text={currentSearchText}
            />
          </div>
          <form
            id="form-search-by-address"
            ref={formRef}
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              console.log("[LOG] Submit form!!");
            }}
          >
            <div id="map-search-by-address">
              <input
                onInput={(e) => {
                  // Disable search button if input changes
                  setReady(false);
                }}
                type="text"
                defaultValue=""
                ref={autocompleteInputRef}
                id="autocomplete"
                className="pill half"
                placeholder="Near address or place"
              />
              <Button
                type="button"
                id="search-address"
                className="pill half-flipped"
                icon={iconSearchAddress}
                text={addressSearchText}
                disabled={
                  !ready ||
                  !autocompleteInputRef.current.value ||
                  searchFacets.currentLocationToggle
                }
                onClick={() => {
                  gaEventTracker("search", "address");
                  handleClick_SelectLocation();
                  setSearchFacets({
                    ...searchFacets,
                    currentLocationToggle: false,
                    selectLocationToggle: true,
                  });
                }}
              />
            </div>
          </form>
        </div>
        <div id="map-search-toggles">
          <span>
            <Button
              type="button"
              className={
                !searchFacets.private ? "pill full facet" : "pill full"
              }
              icon={<FontAwesomeIcon icon={faKey} size="xl" />}
              text={privateButtonText}
              onClick={() => {
                updateVisibleFarms({
                  ...searchFacets,
                  private: !searchFacets.private,
                });
                setSearchFacets({
                  ...searchFacets,
                  private: !searchFacets.private,
                });
              }}
            />
          </span>
          <span>
            <Button
              type="button"
              className={!searchFacets.public ? "pill full facet" : "pill full"}
              icon={<FontAwesomeIcon icon={faHouseFlag} size="xl" />}
              text={publicButtonText}
              onClick={() => {
                updateVisibleFarms({
                  ...searchFacets,
                  public: !searchFacets.public,
                });
                setSearchFacets({
                  ...searchFacets,
                  public: !searchFacets.public,
                });
              }}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
